@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

// tailwind css
@layer components {
  .tailwind-subtitle-grey {
    @apply text-base text-zinc-600 font-bold text-left;
  }

  .tailwind-subtitle-black {
    @apply text-base text-black font-bold text-left;
  }

  // input {
  //   @apply focus: ring-0 focus:ring-offset-0;
  // }
}

input {
  &:focus {
    // border: transparent !important;
    --tw-ring-color: transparent !important;
  }
}

// tailwind css
@font-face {
  font-family: "Gotham-Bold";
  src: url("./assets/fonts/Gotham\ Bold.otf");
}

@font-face {
  font-family: "Gotham-Book";
  src: url("./assets/fonts/Gotham-Book-Regular.otf");
}

html {
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 85px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1vw !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #bb2020;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #00000099;
}

.MuiTypography-subtitle2 {
  font-weight: bold !important;
}

.company-complete {
  input {
    height: 45px;
    max-width: 230px;
  }
}

.card-body {
  color: #00000099;
  flex: 1;
  padding: 2%;

  h6 {
    margin: 5px;
    margin-left: 0;
  }

  .card-body-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    // justify-content: space-between;
    h1 {
      margin: 2px;
      color: black;
    }

    .card-body-header-details {
      text-align: left;
      margin-left: 20px;
    }

    p {
      margin: 0px;
      font-size: 1vw !important;
      // font-family: "Gotham-Book";
    }

    .card-header-image {
      height: 130px;
      width: 130px;
      position: relative;
      margin-right: 20px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }

      .card-body-company {
        background-color: white;
        border-radius: 50%;
        font-size: 16px;
        font-family: "Gotham-Bold";
        height: 50px;
        width: 50px;

        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 100%;
        left: 100%;
        transform: translate(-100%, -100%);
      }
    }
  }

  .card-body-description {
    height: fit-content;
    width: 100%;
    margin-top: 2%;
    text-align: left;
    min-height: 150px;

    p {
      overflow: hidden;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: 1rem;
      text-overflow: ellipsis;
      text-align: left;

      letter-spacing: 0.25px;
      color: #00000099;
      opacity: 1;
    }
  }

  .card-body-interests {
    text-align: left;
  }

  .interest-list {
    width: 100%;
    display: flex;
    flex-direction: row;

    img {
      height: 30px;
    }
  }
}

.card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 2%;
  align-items: center;
  justify-self: flex-end;
}

@mixin transition-left {
  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      transform: rotate(-30deg);
    }
  }
}

@mixin transition-right {
  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      transform: rotate(30deg);
    }
  }
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
}
.MuiSwitch-switchBase.Mui-checked {
  color: #19d279 !important;
}

.tiny-spark {
  height: 50px;
  width: 100px;
  // margin-top: -35px;
}

.card-body-company-match {
  color: #00000099;
  flex: 1;
  padding: 2%;

  h6 {
    margin: 5px;
    margin-left: 0;
  }

  .card-body-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    // justify-content: space-between;
    h1 {
      margin: 2px;
      color: black;
    }

    .card-body-header-details {
      text-align: left;
      margin-left: 40px;
      width: 201px;
      height: 73px;
    }

    p {
      margin: 0px;
      font-size: 1vw !important;
    }

    .card-header-image {
      height: 130px;
      width: 130px;
      position: relative;
      margin-right: 2%;

      img {
        height: 100%;
        width: 100%;
      }

      .card-body-company {
        background-color: white;
        border-radius: 50%;
        font-size: 20px;
        height: 75px;
        width: 75px;
        font-weight: bold;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 100%;
        left: 100%;
        transform: translate(-100%, -100%);
      }
    }
  }

  .card-body-description {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;

    p {
      margin-right: 2%;
      font-size: 1vw !important;
      overflow: hidden;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      text-overflow: ellipsis;
    }
  }

  .interest-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    .interest-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    img {
      height: 30px;
    }
  }

  .card-body-company-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    padding-top: 10px;

    p {
      margin: 2px;
    }
  }
}

@keyframes yay {
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: translateY(-150px) translateX(-300px) rotate(-40deg) scale(0.9);
    opacity: 0;
  }
}

@keyframes nope {
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: translateY(150px) translateX(300px) rotate(40deg) scale(0.9);
    opacity: 0;
  }
}

.mycard-enter {
  opacity: 0;
  transform: rotate(40deg) translateY(-80px);
}

.mycard-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.mycardRight-exit {
  opacity: 1;
}

.mycardRight-exit-active {
  opacity: 0;
  // transition: opacity 300ms, transform 300ms;
  animation: yay 0.7s ease-out;
}

.mycardLeft-exit {
  opacity: 1;
}

.mycardLeft-exit-active {
  opacity: 0;
  // transition: opacity 300ms, transform 300ms;
  animation: nope 0.7s ease-out;
}

.company-ticker {
  text-align: left;
  margin-bottom: 10px;

  p {
    margin: 0;
  }
}

.flex-boy-row {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-boy-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.circle-progress {
  width: 20vh;
  height: 20vh;
  padding: 5px;
  box-sizing: border-box;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  position: relative;

  .progress {
    height: 100%;
    width: 100%;
  }

  img {
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.company-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 10px;
}

.messageBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 70px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.connection {
  height: 50px;
  width: 50px;
  background-color: #00ac69;
  color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invitee-popover {
  width: 300px;
  height: 300px;
  position: relative;
}

.chat-container {
  height: 75vh;
  overflow-y: hidden;
  background-color: #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0 5px 15px -11px rgba(0, 0, 0, 0.88);

  .chat-body {
    flex: 1;
    overflow-y: scroll;
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
  }

  .MuiOutlinedInput-input {
    padding: 10px 7px;
  }
}

.message-profile-image {
  height: 50px;
  width: 50px;

  border-radius: 50%;
}

.placeholder-image {
  min-width: 90px;
  min-height: 130px;
  background-color: white;
  margin-right: 5px;
  border: 1px solid #00000061;
  opacity: 1;
}

.browse-content-head {
  width: 100%;
  height: fit-content;
  background-color: white;
}

.project-div {
  width: 100%;
  // max-width: 560px;
  position: relative;
  border-bottom: 1px solid #00000033;
  box-sizing: border-box;
  padding: 10px;
  padding-top: 30px;
  text-align: left;
  cursor: pointer;

  &:hover {
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    background-color: #f0f0f0 !important;
  }

  // .project-flag {
  //   &:hover {
  //     // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  //     -webkit-filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
  //     filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
  //   }
  // }

  .project-image {
    min-width: 90px;
    min-height: 130px;
    background-color: white;
    margin-right: 15px;

    border: 1px solid #00000061;
    // border-radius: 5px;
    opacity: 1;

    &:hover {
      position: absolute;

      transform: scale(5);
      transform-origin: 0 0;
      transition: transform 0.25s, visibility 0.25s ease-in;
      z-index: 300;
      border-color: gray;
    }
  }
}

.peformance-chart-box {
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;

  .period {
    box-sizing: border-box;
    padding: 5px;
    border-radius: 7px;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes magnified {
  0% {
    transform: scale(1.2, 1.2);
  }

  70% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.loading-img {
  animation: pulse 2s infinite, magnified 2s infinite;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-button {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #eee;
  border: thin solid transparent;
  box-shadow: 0px 0px 3px #dfdfdf inset;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(216, 216, 216);
  border: thin solid rgb(209, 209, 209);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #dbdbdb;
}

.ais-Hits-item {
  padding: 5px !important;
}

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 20px !important;
  box-shadow: none !important;
  caret-color: #5a5e9a;
  color: #23263b;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  font: inherit;
  max-width: 100%;
  padding-left: 2.5rem;
}

.project-description {
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 1rem;
  text-overflow: ellipsis;
  text-align: left;
  word-break: break-word;
  letter-spacing: 0.25px;
  color: #00000099;
  opacity: 1;
}

.connect-button {
  border: 1px solid #00ac69;
  background-color: white !important;
  cursor: pointer;

  &:hover {
    background-color: rgb(241, 241, 241) !important;
  }
}

$breakpoint-tablet: 768px;

.map {
  height: calc(100vh - 80px);
  width: 100%;
  transition: width 0.24s ease-in-out;
  position: fixed;
  top: 80px;
  // @media screen and (max-width: $breakpoint-tablet) {
  //   //do Smth
  //   display: none;
  // }
}

.list-section {
  width: 100%;

  @media screen and (max-width: $breakpoint-tablet) {
    //do Smth
    max-width: 600px;
  }

  .sticky {
    position: sticky;
    top: -5px;
    background-color: white;
    z-index: 100;
    // border-bottom: #463939 1px solid;
    // box-shadow: 0px 3px 6px #00000029;
    // box-shadow: 0px 18px 51px -44px #00000029;
  }
}

.active-fiter-item {
  background-color: #00ac694d;
  border: 1px solid #00ac69 !important;

  &:hover {
    background-color: #00ac694d !important;
  }
}

.dropzone-popover {
  box-sizing: border-box;
  padding: 10px;
  min-height: 300px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.remove-image {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.news-section {
  flex: 1;
  width: 100%;

  .news-article {
    width: 100%;
    align-items: normal;
    margin-top: 10px;
    border-bottom: 1px solid gainsboro;
    flex-wrap: wrap;
    cursor: pointer;

    img {
      flex: 1;
      width: 100px;
    }
  }
}

.charts-section {
  // flex: 1;
  margin-left: 10px;
  margin-top: 10px;
  width: 30%;
}

.insights-map {
  height: 700px;
}

.map-filters {
  background-color: white;
  border-radius: 5px;
  height: 150px;
  border: 1px solid gainsboro;
  border-top: none;
  box-sizing: border-box;
  padding: 10px;

  .map-label {
    margin: 10px;
  }
}

.insights-chart {
  width: 100%;
  height: 400px;
  border: 1px solid gainsboro;
  text-align: left;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
}

.recommended-projects {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .recommended-project {
    background-color: #4cc596;
    cursor: pointer;
    border-radius: 7px;
    border: 1px solid black;
    height: 200px;
    width: 200px;
    text-align: left;
    margin: 10px;
    transition: all 0.2s ease-in-out;
    transform-origin: top left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: 1rem;
      text-overflow: ellipsis;
      text-align: left;
    }

    box-sizing: border-box;
    padding: 10px;

    &:hover {
      transform: scale(1.03);
    }
  }
}

.news-article {
  text-align: left;
  width: 49%;
  cursor: pointer;
  min-height: 130px;
  border-radius: 4px;
  border: 1px solid gainsboro;
  margin-right: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
  padding: 10px;
  text-align: left;

  .article-summary {
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    text-overflow: ellipsis;
    text-align: left;
  }

  transition: all 0.2s ease-in-out;
  transform-origin: top left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  padding: 10px;

  &:hover {
    transform: scale(1.01);
    border-color: gray;
  }
}

.number-of-companies {
  border-radius: 25px;
  margin: 5px;
  text-align: center;
  cursor: pointer;
}

.loosers {
  background-color: #e7b3bd;
  // temporary
  width: 70%;
}

.stagnant {
  background-color: gainsboro;
}

.gainers {
  background-color: #b3efb4;
  width: 30%;
}

.chart-bar-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14.3%;
  justify-content: flex-end;
  cursor: pointer;
}

.insights-legend {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 5px solid #808080;
  justify-content: center !important;
  margin-right: 2px;
  background-color: white;
}

.marker-popover {
  width: 250px;
  height: fit-content;
  position: relative;
  box-sizing: border-box;
}

.statistics {
  flex-wrap: wrap;

  .statistic {
    width: 50%;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
  }
}

.pie {
  width: 48%;

  margin: 2px;
}

.article-summary {
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  text-overflow: ellipsis;
  text-align: left;
}

.article-project-data {
  padding: 5px;
  background-color: gainsboro;
  min-height: 50px;
  cursor: pointer;
}

.instant-search {
  position: relative;
  top: 10%;
  flex: 1;

  box-sizing: border-box;

  text-align: left;
  height: fit-content;

  .right-panel {
    padding: 10px;
    margin-top: 25px;
    background-color: whitesmoke;
    border-radius: 7px;
    border: 1px solid gainsboro;
  }
}

.instant-search-iframe {
  position: relative;
  top: 10%;
  width: 100%;

  box-sizing: border-box;

  text-align: left;
  height: fit-content;

  .right-panel {
    height: fit-content;

    padding: 10px;
    margin-top: 25px;
    background-color: whitesmoke;
    border-radius: 7px;
    border: 1px solid gainsboro;
  }
}

.hit-summary {
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  text-overflow: ellipsis;
  text-align: left;
}

.marker {
  height: 5vh;
  width: 5vh;
  position: relative;

  transform: translate(-50%, -50%);
  // background-color: transparent;
  // box-shadow: 0 5px 36px -11px rgba(0, 0, 0, 0.88);
  // transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  &:hover {
    -webkit-filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
    filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
    border: white 3px solid;

    width: 5vh;
    border-radius: 50%;
  }
}

.chart-map {
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 400px;

  .company-map {
    flex: 1;
  }
}

.MuiAccordion-root.Mui-expanded:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.prospects-filter {
  height: fit-content;

  &:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
    filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
  }
}

.insights-slider-graph-section {
  height: 50px;
  width: fit-content;
  box-sizing: border-box;
  padding: 10px;

  &:hover {
    cursor: pointer;
    -webkit-filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
    filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
  }
}

.pac-container {
  z-index: 2001 !important;
}

// .MuiAccordion-root {
//   border-radiu: 5px !important;
// }
.subscription {
  min-height: 400px;
  max-height: 500px;
  height: 100%;
  width: 100%;
  position: relative;

  .bg-img {
    background: url("./assets/ProspectorDesignAssets/43-101 Authors - smaller.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(18px);
    width: 100%;
    height: 100%;
    -webkit-filter: blur(18px);
  }

  .sub-box {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.226);
    /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 50%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.subscription-box {
  min-height: 400px;
  max-height: 500px;
  height: 100%;
  width: 100%;

  transform: translate(-50%, -50%);

  .bg-img {
    background: url("./assets/ProspectorDesignAssets/43-101 Authors - smaller.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(18px);
    width: 100%;
    height: 100%;
    -webkit-filter: blur(18px);
  }

  .sub-box {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.226);
    /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 50%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.subscription-status {
  height: 100%;

  width: 100%;

  background-color: rgba(220, 220, 220, 0.376);

  top: 0;
  left: 0;

  .bg-img {
    background: url("./assets/ProspectorDesignAssets/blur.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(18px);
    width: 100%;
    height: 100%;
    -webkit-filter: blur(18px);
  }

  .bg-img2 {
    background: url("./assets/ProspectorDesignAssets/blur2.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(18px);
    width: 100%;
    height: 100%;
    -webkit-filter: blur(18px);
  }

  .sub-box {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.226);

    color: white;
    font-weight: bold;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 50%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.subscription-content {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.account-body {
  position: absolute;
  top: 106px;
  right: 50px;
  min-width: 350px;
  width: fit-content;
  height: fit-content;
  min-height: 60vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  z-index: 990;
  transition: opacity 0.2s ease;
  box-sizing: border-box;
  padding: 10px;

  .profile-pic {
    height: 150px;
    width: 100%;
    position: relative;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }

    .edit {
      border-radius: 50%;
      background-color: #00ac69;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 40%;
      right: 32%;
      cursor: pointer;
    }
  }
}

.nav-dropdown {
  position: absolute;
  width: 220px;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;

  z-index: 3000;
  transition: transform 0.4s ease-out, opacity 0.1s ease-out;
  background-color: whitesmoke;

  ul {
    display: flex;
    padding: 0 !important;
    flex-direction: column;
    margin: 0 !important;

    li {
      background-color: whitesmoke;
      width: 100%;
      color: #463939;

      &:hover {
        color: white;
        background-color: #00ac69;
      }
    }
  }

  .unifiednav__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
  }

  .unifiednav__item-wrap {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-weight: 400;
  }

  .rotate {
    transition: transform 1s ease;
  }

  .unifiednav__item {
    font-size: 14px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    padding: 12px 15px;
    margin: 0;
    font-weight: 100;
    padding: 15px 30px 15px 15px !important;
  }
}

// .sticky {
//   position: sticky;
//   top: -5px;
//   background-color: white;
//   z-index: 100;
//   // border-bottom: #463939 1px solid;
//   // box-shadow: 0px 3px 6px #00000029;
//   // box-shadow: 0px 18px 51px -44px #00000029;
// }

.profile-update {
  height: 300px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 20px;

  img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
  }

  .edit {
    border-radius: 50%;
    background-color: #00ac69;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40%;
    right: 32%;
    cursor: pointer;
  }
}

.period {
  box-sizing: border-box;
  padding: 5px;
  border-radius: 7px;
  cursor: pointer;
}

.period-map {
  box-sizing: border-box;
  padding: 5px;
  border-radius: 7px;
  cursor: pointer;
}

.circle-div {
  height: 52px;
  width: 52px;
  display: flex;
  margin: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid gray;
  cursor: pointer;

  &:hover {
    border-color: gainsboro;
  }
}

.scroll-top-button {
  position: fixed;
  top: 90vh;
  left: 25%;
}

.period-map-parent {
  position: fixed;
  top: 95px;
  z-index: 200;
  background-color: white;
  width: 450px;
  left: 70%;
  transform: translate(-50%);
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 5px;

  @media screen and (max-width: $breakpoint-tablet) {
    //do Smth
    transform: scale(0.7);
    top: 2%;
    left: -7%;
  }
}

.mapfullscreen {
  position: sticky;
  background-color: white;
  top: 80px;
  margin-left: 10px;
  height: 52px;
  width: 52px;
  z-index: 200;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.cluster-marker {
  color: #fff;
  background: #00ac69;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid rgba(255, 255, 255, 0.801);
  box-shadow: 0px 3px 6px #00000029;
}

.project-pies {
  .pie {
    height: fit-content;
    margin-top: 20px;
  }
}

#insight-filters {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%);
}

.nav-item-text {
  text-align: left;
}

.notes-dialog {
  height: 600px;

  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 4px;

  .body {
    min-height: 500px;
    height: fit-content;
    padding-right: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1000;
    left: 0;
    background-color: white;
  }
}

.save-changes {
  width: 300px;

  height: 60px;
  justify-content: center;
  border-radius: 5px;
}

.list_body {
  height: 500px;
  width: 100%;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: normal;
  align-items: flex-start;

  .icon-box {
    height: 50px;
    width: 50px;
    background-color: #f5f8f5;
    margin-right: 10px;
    justify-content: center;
    padding: 4px;

    img {
      height: 100%;
    }
  }

  .svg_box {
    height: 50px;
    width: 50px;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;

    img {
      height: 100%;
    }

    background-color: #f5f8f5;
    // border: 2px solid #00ac69;
    margin-right: 10px;
  }
}

.icon-selector {
  width: 350px;
  height: 400px;
  overflow-y: scroll;
  background-color: white;
  padding: 10px;
  position: relative;
}

.list_item {
  margin-bottom: 10px;
  margin-top: 10px;
}

.icon-svg {
  padding: 10px !important;
  box-sizing: border-box;

  &:hover {
    background-color: #e8f9e9;
  }
}

.search-emoticons {
  position: sticky;
  top: -10px;
  background-color: white;
  padding: 10px;
}

.hover-icon {
  border-radius: 5px;

  &:hover {
    background-color: gainsboro;
  }
}

.projectNote {
  cursor: pointer;
}

.hover-sub {
  &:hover {
    box-shadow: -2px 0px 10px -4px rgba(0, 0, 0, 0.48);
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.arrow-hover {
  &:hover {
    border-radius: 50%;
    background-color: #dbdbdb;
  }
}

.page-anchors {
  text-align: left;
  padding: 10px;

  p {
    font-size: 16px !important;
    cursor: pointer;
  }
}

.iframe-cover {
  width: 200px;
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.daterangepicker-control-section {
  .e-control,
  .e-btn {
    font-size: 20px !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
}

.MuiTab-root {
  min-width: 100px !important;
}

.stage-card {
  height: 50px;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid gainsboro;
  justify-content: normal;
  width: 30%;
  cursor: pointer;

  img {
    height: 40px;
  }
}

.browse-page-wide {
  .Mui-selected {
    border-left: #00ac69 3px solid !important;
  }

  .MuiTabs-indicator {
    background-color: transparent !important;
  }
}

.connections-page-wide {
  .Mui-selected {
    border-left: #00ac69 3px solid !important;
  }

  .MuiTabs-indicator {
    background-color: transparent !important;
  }
}

//}
//  .Mui-selected {
//    border-bottom: #00ac69 3px solid !important;

.notched {
  flex: 1;

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
}

.report-summary {
  min-height: 320px;
  border: 1px solid gainsboro;
  border-radius: 25px;

  margin: 10px;
  margin-top: 0;

  .header {
    background-color: #00ac69;
    height: 40px;
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
  }

  .body {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;

    div {
      width: 100%;
      text-align: left !important;
    }
  }
}

.company-content {
  cursor: pointer;
  margin: 10px;
  position: relative;
  padding: 10px;

  &:hover {
    box-shadow: -2px 0px 10px -4px rgba(0, 0, 0, 0.48);
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.calculated-height {
  height: calc(100vh - 80px);
  position: sticky;
  top: 80px;
}

.e-date-wrapper .e-popup .e-calendar .e-header .e-title {
  color: #00ac69;
  font-size: 20px;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #c8c8c8 !important;
  box-shadow: none !important;
}

.e-date-wrapper {
  &:focus {
    border-color: #00ac69 !important;
    box-shadow: none !important;
  }

  .e-input {
    &:focus {
      border-color: #00ac69 !important;
      box-shadow: none !important;
    }
  }
}

#daterangepicker {
  border-color: #00ac69 !important;
}

// .MuiInputBase-input {
//   font-size: 19px !important;

//   &::placeholder {
//     color: hsl(0, 0%, 50%) !important;
//     opacity: 1 !important;
//   }
// }

.MuiFormControl-root {
  margin: 0 !important;
}

.grid-container {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: auto auto auto auto;
  position: relative;
}

.document-figure {
  position: relative;

  img {
    &:hover {
      position: absolute;
      transform: scale(2);
      //transform-origin: 0 0;
      transition: transform 0.25s, visibility 0.25s ease-in;
      z-index: 300;
      border-color: gray;
    }
  }
}

a {
  text-decoration: none !important;
}

.chart-div {
  border-right: 1px solid gainsboro;
  // min-height: 100px;
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
}

.table-grid {
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 15px Arial, sans-serif;
}

#ranger {
  canvas {
    height: 40px !important;
    width: 150px !important;
  }
}

.media-cahrt {
  @media screen and (min-width: $breakpoint-tablet) {
    //do Smth
    height: 600px !important;
  }
}

.table-grid {
  td {
    height: 20px;
  }
}

tr {
  height: 20px;
}

.news-filter {
  .MuiInput-underline {
    &:before {
      border-bottom: transparent !important;
    }
  }
}

.searchpage {
  .MuiOutlinedInput-input {
    padding: 16px 10px !important;
  }
}

.rhap_repeat-button {
  color: white !important;
}

.sticky-tabs {
  position: sticky;
  top: -5px;
  background-color: white;
  z-index: 100;
  // border-bottom: #463939 1px solid;
  // box-shadow: 0px 3px 6px #00000029;
  // box-shadow: 0px 18px 51px -44px #00000029;
}
.infinite-scroll-component__outerdiv {
  overflow-y: scroll;
  height: 80vh;
}

.Toastify__toast-body {
  font-size: medium;
}

#react-select-30-listbox {
  z-index: 10000;
}
.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  z-index: 10000;
}

.filter-container input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-container button {
  padding: 5px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.custom-slider .range-slider__range {
  background-color: #6ee7b7 !important;
  /* Light gray background */

  /* Thickness of the range track */
}

.custom-slider .range-slider__range[data-active] {
  background-color: #6ee7b7 !important;
  /* Emerald color for active track */
}

.custom-slider .range-slider__thumb {
  background-color: #10b981 !important;
  /* Emerald color for thumbs */
  border: 2px solid white !important;
  width: 16px !important;
  /* Thumb width */
  height: 16px !important;
  /* Thumb height */
}

.custom-slider .range-slider__thumb[data-active] {
  background-color: #059669 !important;
  /* Darker green for active thumb */
}

.custom-slider .range-slider__thumb[data-disabled] {
  background-color: #a7f3d0 !important;
  /* Lighter green for disabled thumb */
}
