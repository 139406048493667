.slider-handles div[role="slider"] {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #00ac69;
}

.slider-tracks div[role="track"] {
  background-color: #00ac69;
}

.min-max-label {
  background: #0000001e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  height: 30px;
  width: 120px;
  float: right;
  padding: 3px;
}

.chemical-symbol {
  background: #3dd598;
  width: 53px;
  height: 47px;
  border-radius: 5px;
  color: white;
  font-family: Gotham-bold;
  font-size: 22px;
  padding: 5px 0 0 0px;
  margin: 0 20px 0 0;
}
