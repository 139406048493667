.prospect-search-by-name {
  font-size: 13px;
  min-width: 230px;
  padding: 2px 10px;
  border: 1px solid #d1d1d1;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.prospect-search-icon {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #00ac69;
  padding: 0px 6px;
  margin-left: -2px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 30px;
}

.filter-active-btn {
  background-color: #00ac69 !important;
}

.filter-my-tags-btn {
  /* UI Properties */
  /* background-color:  #00ac69; */
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  opacity: 1;
  width: 90px;
  height: 26px;
  margin-left: 5px;
  font-size: 12px;
}

.filter-my-list-icon {
  background: transparent url("../../../assets/icon/arrow_drop_down.svg") 0% 0%
    no-repeat padding-box;
  /* background-color:  #00ac69 !important; */
  background-position: right;
}

.filter-sort-btn {
  background: transparent url("../../../assets/icon/sort-icon.svg") 0% 0%
    no-repeat padding-box;
  background-position: center;
  opacity: 1;
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  width: 26px;
  height: 26px;
  margin-left: 5px;
}

.filter-download-pdf-btn {
  background: transparent url("../../../assets/icon/pdf-icon.png") 0% 0%
    no-repeat padding-box;
  background-size: 15px 15px;
  background-position: left;
  border-radius: 5px;
  opacity: 1;
  width: 145px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
}

.filter-download-csv-btn {
  background: transparent url("../../../assets/icon/csv-icon.png") 0% 0%
    no-repeat padding-box;
  background-size: 15px 15px;
  background-position: left;
  border-radius: 5px;
  opacity: 1;
  width: 145px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
}

.filter-btn {
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  opacity: 1;
  width: 26px;
  height: 26px;
  margin-left: 5px;
}

.filter-export-btn {
  background: transparent url("../../../assets/icon/share-icon-black.svg") 0% 0%
    no-repeat padding-box;
  background-position: center;
  opacity: 1;
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  opacity: 1;
  width: 26px;
  height: 26px;
  margin-left: 5px;
}

.filter-my-tags-children-btn {
  /* UI Properties */
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  opacity: 1;
  margin: 5px 5px 5px 5px;
  padding: 2px 5px 2px 5px;
  font-size: 12px;
}

.filter-my-list-children-checkbox {
  color: #00ac69;
}
