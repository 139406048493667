.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.e-calendar .e-content td:hover span.e-day {
  background-color: #00ac69;
  color: white;
}
.e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover.e-today
  span.e-day,
.e-bigger.e-small
  .e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover.e-today
  span.e-day {
  background-color: #00ac69;
  border: 1px solid #00ac69;
  color: #fff;
}
.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #00ac69;
  border: none;
  border-radius: 50%;
  color: #fff;
}
.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #00ac69;
  border: 1px solid #00ac69;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}
.e-daterangepicker.e-popup
  .e-calendar
  .e-end-date.e-selected.e-range-hover
  span.e-day,
.e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover
  span.e-day,
.e-bigger.e-small
  .e-daterangepicker.e-popup
  .e-calendar
  .e-end-date.e-selected.e-range-hover
  span.e-day,
.e-bigger.e-small
  .e-daterangepicker.e-popup
  .e-calendar
  .e-start-date.e-selected.e-range-hover
  span.e-day {
  background-color: #00ac69;
  color: #fff;
}
.e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:disabled,
.e-daterangepicker.e-popup .e-footer .e-btn.e-apply.e-flat.e-primary:disabled,
.e-daterangepicker.e-popup
  .e-footer
  .e-css.e-btn.e-apply.e-flat.e-primary:disabled,
.e-daterangepicker.e-popup
  .e-footer
  .e-css.e-btn.e-apply.e-flat.e-primary:disabled {
  background-color: #00ac69;
  color: white;
}
.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: transparent;
  border-color: transparent;
  color: #00ac69;
}

.e-daterangepicker.e-popup .e-footer .e-btn.e-apply,
.e-bigger.e-small .e-daterangepicker.e-popup .e-footer .e-btn.e-apply {
  margin: 0 8px 0 8px;
}
.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  background-color: rgba(227, 22, 91, 0.04);
  border-color: transparent;
  color: #00ac69;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: transparent;
}
.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
  height: 40px;
}
.e-date-range-wrapper {
  border: 1px solid #d1d1d1 !important ;
  border-radius: 3px !important;
}

.flex-boy-row-resources-and-reserves-select {
  width: 40%;
}
.flex-row-boy-annual-production-select,
.flex-boy-row-project-detail-select {
  width: fit-content;
}

.align-items-top {
  align-items: inherit !important;
}

.prospect-search-by-name {
  font-size: 13px;
  min-width: 230px;
  padding: 2px 10px;
  border: 1px solid #d1d1d1;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.prospect-search-icon {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #00ac69;
  padding: 0px 6px;
  margin-left: -2px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 26px;
}

.prospects-table:nth-child(odd) p,
.prospects-table:nth-child(odd) .prospects-hover-icon {
  background-color: #d1d1d13b;
  height: 30px;
  display: flex;
  align-items: center;
}

.notes-body > .name,
.notes-body > .projectName,
.notes-body > .latest_report {
  color: #00ac69 !important;
}

.note-header {
  position: sticky;
  top: 90px;
  z-index: 100;
}
.note-header p,
.notes-body p {
  min-width: 180px;
  padding: 5px;
}

.notes-body > .name,
.note-header > .name,
.notes-body > .projectName,
.note-header > .projectName {
  min-width: 250px;
}

.flex-boy-row-project-stage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.prospect-table {
  /* overflow-x: scroll; */
}

.prospector-bottom-date {
  margin-top: 300px;
}

.text-grey {
  color: grey;
  margin-bottom: 2px;
}

hr {
  height: 2px;
  background-color: black;
}

.e-for-pdf-download {
  /* border-bottom: #00ac69 solid 2px; */
  margin-bottom: 20px;
  margin-top: 0 !important;
}

.pdf-preview {
  background-color: #d1d1d1;
  padding: 2%;
  margin: 2%;
}

.border-green {
  border-bottom: #00ac69 solid 2px;
}

.text-right {
  text-align: right;
}

.preview-pdf {
  background-color: aliceblue;
  margin: 2%;
  padding: 2%;
}

.flex-boy-row-preview-pdf {
  display: flex;
  align-items: center;
  justify-content: end;
}

.pdf-download-confirm {
  margin-left: 10px;
  font-size: 15px;
  color: #00ac69;
  font-weight: 800;
  padding: 6px;
  border-radius: 3px;
  border: #00ac69 1px solid;
}

.flex-boy-row-prospect-header {
  margin-bottom: 20px;
  position: sticky;
  z-index: 100;
  top: -5px;
  background: white;
}
@media (max-width: 768px) {
  .map {
    width: 100% !important;
    position: static !important;
  }

  .MuiTabs-flexContainer {
    display: contents !important;
  }

  .MuiDialog-paperScrollPaper .flex-boy-column {
    width: 300px !important;
  }

  .flex-boy-row div h6 {
    margin-top: 20px;
    min-height: 30px;
  }

  .flex-boy-row {
    font-size: 15px;
  }

  .flex-boy-row-minerals,
  .flex-boy-row-exchanges,
  .flex-boy-row-resources-and-reserves,
  .flex-row-boy-annual-production,
  .flex-row-boy-quarterly-production,
  .company-page-wrapper,
  .flex-boy-row-resources-and-reserves-select,
  .flex-row-boy-annual-production-select,
  .calculator-wrapper,
  .flex-boy-row-project-detail-select {
    display: block !important;
  }

  .flex-boy-row-project-stage .flex-boy-row,
  .flex-boy-row-stock-performance-news,
  .flex-boy-row-resources-and-reserves-select,
  .flex-row-boy-annual-production-select,
  .flex-boy-row-project-detail-select {
    width: 100%;
  }

  .project-detail-wrapper,
  .company-page-left,
  .company-page-right {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .subscription .sub-box {
    width: 80% !important;
  }

  .flex-boy-row-company,
  .flex-boy-row-prospect-header {
    display: contents;
  }

  .company-company-detail {
    border: none !important;
  }

  .flex-boy-row-left {
    justify-content: left;
    margin-bottom: 10px;
  }

  .filter-my-tags-btn {
    margin-left: 0 !important;
    margin-right: 10px;
  }

  .prospect-content {
    padding: 10px !important;
  }
}
